/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import useSWRMutation from 'swr/mutation';

import { ISpecialistShortData } from '1_shared/config/interfaces/ISpecialistShortData';

import { specApiInstance } from '../../../1_shared/api/apiInstance';
import { ISlot } from '../../../1_shared/config/interfaces/ISlot';
import { IApplicationForm } from '../ui/interface/IApplicationForm';

import { IPromocodeInput } from './api/interfaces/IPromocodeInput';
import { IPromoCodeOutput } from './api/interfaces/IPromoCodeOutput';
import { makeOrder } from './api/specService';
import {
  IPaymentHookOutput,
} from './interfaces/IPaymentHookOutput';

const usePayment = (
  updateNickname?: any,
): IPaymentHookOutput => {
  const [promoCode, setPromoCode] = useState<IPromoCodeOutput | undefined>();
  const [errorPromo, setErrorPromo] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPromoCodeFind = async (promo: IPromocodeInput) => {
    try {
      setIsLoading(true);
      const req = await specApiInstance.get('ls/promocodes/v1/find', {
        params: promo,
      });
      setPromoCode(req.data);
      setIsLoading(false);
    } catch (e) {
      setErrorPromo((e as AxiosError<any>).response?.data?.message);
      setIsLoading(false);
    }
  };

  const { trigger: makeOrderTrigger } = useSWRMutation('/ss/uz-api/order/make-order', makeOrder);

  const payment = async (
    cost: number,
    data: IApplicationForm,
    spec: ISpecialistShortData,
    slot: ISlot,
  ) => {
    if (updateNickname) await updateNickname(data?.nickname);
    const order = await makeOrderTrigger({
      ...data,
      price: cost,
      discount: Number(promoCode?.discount) || 0,
      discountedPrice: cost - Number(promoCode?.discount || 0),
      promoCode: data?.promoCode || null,
    });

  };

  return {
    payment,
    onPromoCodeFind,
    promoCode,
    errorPromo,
    isPromoLoading: isLoading,
    setPromoCode,
  };
};

export default usePayment;
